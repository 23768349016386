

import Vue from 'vue'
import { mapGetters } from 'vuex'
import IdleLogout from '~/components/common/addons/IdleLogout.vue'
import UserMenu from '~/components/site/UserMenu.vue'
// @ts-ignore
import logoDark from '~/assets/images/xosen-1.png'

export default Vue.extend({
  components: {
    IdleLogout,
    UserMenu
    // Messenger,
    // Notificator
  },
  props: {
    hasMenu: Boolean
  },
  data () {
    return {
      drawer: false,
      // @ts-ignore
      title: this.$nuxt.$options.head!.title
    }
  },
  computed: {
    ...mapGetters([
      'profile',
      'isAuthenticated',
      'installPrompt',
      'canInstall',
      'navigationDrawer',
      'isRemembered'
    ]),
    logo () {
      return (this.$vuetify as any).theme.dark ? logoDark : logoDark // TODO add light version
    }
  },
  methods: {
    onDrawerClick () {
      const event = new Event('navigation-drawer')
      document.dispatchEvent(event)
    },
    logout () {
      // @ts-ignore
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/')
      })
    }
  }
})
