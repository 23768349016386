<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="340"
    center
    persistent
    @close="reset"
  >
    <DialogCard
      :title="$t('You reached idle timeout')"
      :actions="actions"
    >
      <p v-if="secondsToExit">
        {{ $t('Application will automatically close after {0} seconds', [secondsToExit]) }}
      </p>
    </DialogCard>
  </v-dialog>
</template>

<script>
import IdleTimeout from 'idle-timeout'

export default {
  props: {
    status: String,
    countdown: {
      type: Number,
      default: 60
    },
    timeout: {
      type: Number,
      default: 20 * 60
    }
  },
  data () {
    return {
      dialogVisible: false,
      secondsToExit: 0,
      resetCount: 0
    }
  },
  computed: {
    actions () {
      return {
        cancel: {
          text: this.$t('Cancel'),
          color: 'primary',
          handler: () => {
            this.dialogVisible = false
            this.reset()
          }
        }
      }
    }
  },
  created () {
    this.idleTimer = new IdleTimeout(this.onIdle, {
      element: document,
      timeout: this.timeout * 1000,
      loop: false
    })
  },
  methods: {
    reset () {
      this.resetCount++
      this.stopCountDown()
      this.idleTimer.timeout = this.timeout * 2 * 1000
      this.idleTimer.reset()
    },
    startCountDown () {
      this.secondsToExit = this.countdown
      this.interval = setInterval(() => {
        this.secondsToExit--
        if (this.secondsToExit <= 0) {
          this.stopCountDown()
          this.idleReached()
          this.dialogVisible = false
        }
      }, 1000)
    },
    stopCountDown () {
      clearInterval(this.interval)
      this.secondsToExit = 0
    },
    onIdle () {
      // Callback
      this.dialogVisible = true
      this.startCountDown()
    },
    idleReached () {
      this.$emit('onIdleTimeout')
    }
  }
}
</script>
