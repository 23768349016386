import fileDialog from 'file-dialog'
import Vue from 'vue'
import { readAndCompressImage } from 'browser-image-resizer'
// import reduce from 'image-blob-reduce'
import PicturePicker from './PicturePicker.vue'

declare interface FileDialogOptions {
  readonly multiple?: boolean;
  readonly accept?: string|ReadonlyArray<string>;
}

export async function showUploadPictureDialog (options: Record<string, any>) {
  const files = await fileDialog({ accept: 'image/*' })
  if (!files[0]) {
    return
  }
  const file = files[0]
  const data = await getFileData(file)
  const src = await Vue.prototype.$dialog.showAndWait(PicturePicker, {
    srcData: data,
    fullscreen: true,
    transition: 'fade-transition',
    ...options
  })
  if (src) {
    return {
      src,
      file: await srcToFile(src, file.name, file.type)
    }
  }
}

export function showFileDialog (options?: FileDialogOptions) {
  return fileDialog({ accept: 'image/*', multiple: true, ...options })
}

export async function resizePictureFile (file: File, options?: Record<string, any>) {
  // const fileRes = await reduce().toBlob(file, Object.assign({ max: 1200 }, options))
  // return fileRes
  if (file.size < 1024 * 1024) {
    return file
  }
  const res = await readAndCompressImage(file, Object.assign({
    quality: 0.8,
    maxWidth: 1200,
    maxHeight: 1200,
    autoRotate: true,
    debug: true
  }, options))
  return new File([res], file.name, { type: file.type, lastModified: file.lastModified })
}

// export async function showFileDialog1 (options) {
//   const files = await fileDialog({ accept: 'image/*', multiple: true, ...options })
//   // const promises = Array.prototype.map.call(files, file => getFileData(file))
//   const promises = Array.prototype.map.call(files, file => resizePictureFile(file, {
//     // quality: 0.8,
//     // maxWidth: 1200,
//     // maxHeight: 1200,
//     // autoRotate: true,
//     // debug: true
//   }).then(getFileData))
//   return Promise.all(promises)
// }

export function getFileData (file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(file)
  })
}

export function srcToFile (src: string, fileName: string, mimeType: string) {
  return (fetch(src)
    .then(res => res.arrayBuffer())
    .then((buf) => {
      return new File([buf], fileName, { type: mimeType })
    }))
}
