<template>
  <div
    class="VStatus"
    :class="{
      _small: small,
      _large: large
    }"
    :style="`display: inline; color: var(--v-${getColor}-base)`"
  >
    <div class="VStatus-dot" :style="`background-color: var(--v-${getColor}-base)`" />
    {{ getText }}
    <slot class="slot" />
  </div>
</template>

<script>

export default {
  props: {
    value: [Boolean, String, Number],
    items: {
      type: Object
    },
    small: Boolean,
    large: Boolean
  },
  computed: {
    getColor () {
      const item = this.items && this.items[this.value]
      if (item && item.color) {
        return item.color
      } else if (typeof this.value === 'boolean') {
        return this.value ? 'success' : 'error'
      }
      return null
    },
    getText () {
      const t = this.items && this.items[this.value]
      return t && t.text
    }
  }
}
</script>

<style lang="scss">
  .VStatus {
    white-space: nowrap;
    &._large {
      .VStatus-dot {
        height: 12px;
        width: 12px;
      }
    }
    &-dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
    }
  }
</style>
