import { Middleware, Context } from '@nuxt/types'

const guestMiddleware: Middleware = ({ store, redirect }: Context) => {
  // If user connected, redirect to /
  if (store.getters.isAuthenticated) {
    return redirect('/')
  }
}

export default guestMiddleware
