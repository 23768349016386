
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: [String, Number],
    colorable: Boolean,
    large: Boolean,
    xLarge: Boolean,
    simple: Boolean
  },
  computed: {
    getValue (): number {
      return +(this.value || 0)
    },
    getColor (): string {
      let type = this.$vuetify.theme.dark ? 'lighten5' : 'base'
      let color = 'secondary'
      if (this.colorable && this.getValue) {
        type = this.$vuetify.theme.dark ? 'lighten1' : 'base'
        color = this.getValue > 0 ? 'success' : 'error'
      }
      return `color: var(--v-${color}-${type})`
    },
    getMoneyText (): string {
      if (this.simple) {
        return '' + this.getValue
      }
      return Math.trunc(this.getValue || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getFractionText (): string {
      return ('' + Math.abs(Math.trunc((this.getValue - Math.trunc(this.getValue)) * 100))).padStart(2, '0')
    }
  }
})
