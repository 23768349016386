<template>
  <v-app>
    <v-main>
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>

export default {
  computed: {
    hasMenu () {
      const path = this.$route.path || ''
      return path.startsWith('/dashboard') || path.startsWith('/system')
    }
  }
}
</script>
