import base64url from 'base64url'

import { Middleware, Context } from '@nuxt/types'

const authMiddleware: Middleware = async ({ store, query }: Context) => {
  if (!process.client || store.getters.isAuthenticated) {
    return
  }
  if (query.token) {
    const tokens = JSON.parse(base64url.decode(query.token as string))
    if (!tokens) {
      return
    }
    await store.dispatch('user/loggedIn', tokens)
  }
}

export default authMiddleware
