import { get } from 'lodash'
// eslint-disable-next-line import/named
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { resizePictureFile } from '~/components/common/pictures/utils'
import { RootState } from '~/store'
import api, { Tenant, TenantMember, User } from '~/api'
import { UUID } from '~/api/interfaces'

type RolyType = 'tenant' | 'system'

export const state = () => ({
  pincodes: {} as Record<string, any>,
  profile: null as Record<string, any> | null,
  meta: null as Record<string, any> | null,
  token: null as string | null,
  rememberMe: false as boolean,
  tenantMember: undefined as TenantMember | undefined,
  isDark: false as boolean
})

export type UserModuleState = ReturnType<typeof state>

const users = {

}

export const mutations: MutationTree<UserModuleState> = {
  SET_TOKENS (state, { token }) {
    state.token = token
    if (token) {
      api.setToken(token)
      ;(this.$axios as any).setToken(token, 'Bearer')
    } else {
      api.setToken(undefined)
      ;(this.$axios as any).setToken(false)
    }
  },
  SET_PROFILE (state, value) {
    state.profile = value
  },
  SET_META (state, meta) {
    state.meta = meta
    // @ts-ignore
    this.app.vuetify?.framework.theme.dark = meta?.DARK_THEME
  },
  SET_META_ITEM (state, { key, value }) {
    if (!state.meta) {
      state.meta = {}
    }
    state.meta[key] = value
  },
  SET_REMEMBERME (state, value) {
    state.rememberMe = value
  },
  SET_TENANT (state, value) {
    state.tenantMember = value
  },
  SET_ACCOUNT_PINCODE (state, payload) {
    if (!payload) {
      state.pincodes = {}
    } else {
      state.pincodes[payload.accountId] = payload.pincode
    }
  }
}

export const getters: GetterTree<UserModuleState, RootState> = {
  tenantExtensions (state) {
    const tenant = state.tenantMember?.tenant
    if (!tenant) {
      return {}
    }
    return (tenant.extensions || []).reduce((acc, i) => {
      acc[i.slug] = true
      return acc
    }, {})
  },
  isDark (state): boolean {
    return !!(state.meta || {}).DARK_THEME
  },
  meta (state): Record<string, any> {
    return state.meta || {}
  },
  tenantMember (state): TenantMember | undefined {
    return state.tenantMember
  },
  can (state): Function {
    return (permission: string, roleType: RolyType = 'tenant' as 'system' | 'tenant'): boolean => {
      const rolePath = roleType === 'tenant' ? 'tenantMember.role.permissions' : 'profile.systemRole.permissions'
      const [perm, action]: any = permission.match(/[^[\]]+/g)
      const val = get(state, `${rolePath}.${perm}`)
      if (!action) {
        return !!val
      }
      if (val && Array.isArray(val)) {
        return val.includes(action)
      }
      return false
    }
  },
  tenant (state): Tenant | undefined {
    return state.tenantMember?.tenant
  }
}

export const actions: ActionTree<UserModuleState, RootState> = {
  logout ({ commit }) {
    api.logout()
    commit('SET_TOKENS', {})
    commit('SET_REMEMBERME', false)
    commit('SET_PROFILE', null)
    commit('SET_META', null)
    commit('SET_ACCOUNT_PINCODE', null)
  },

  async loggedIn ({ commit, dispatch }, tokens) {
    commit('SET_TOKENS', tokens)
    await dispatch('profile')
  },

  async profile ({ commit }) {
    const res = await api.retrieveProfile()
    if (res) {
      commit('SET_PROFILE', res)
      const data = (res && res.data) || {}
      commit('SET_META', data)
      return res
    }
  },

  async setMeta ({ commit, state }, { key, value }) {
    if (!state.profile) {
      return
    }
    await api.updateProfile({ data: { [key]: value } })
    commit('SET_META_ITEM', { key, value })
  },

  async updateProfile ({ dispatch }, { firstName, lastName, email }: Partial<User>) {
    const res = await api.updateProfile({ firstName, lastName, email })
    await dispatch('profile')
    return res
  },

  async uploadAvatar ({ dispatch }, file) {
    const formData = new FormData()
    const resizedFile = await resizePictureFile(file, {
      maxWidth: 400,
      maxHeight: 400
    })
    formData.append('file', resizedFile)

    const avatar = await api.upload('profile/avatar', formData)
    await dispatch('profile')
    return avatar
  },

  async setTenant ({ commit }, id: UUID): Promise<TenantMember | null> {
    const res = await api.retrieveTenant(id)
    api.setTenantId(res.tenant.id)
    commit('SET_TENANT', res)
    return res
  },

  retrieveUser (_, id: UUID) {
    if (!users[id]) {
      users[id] = api.get(`users/${id}`).catch(() => {
        users[id] = null
      })
    }
    return users[id]
  }
}
