import { v4 as uuid } from 'uuid'
import Vue from 'vue'
// import axios, { AxiosRequestConfig } from 'axios'
import { get } from 'lodash'
import pLimit from 'p-limit'
// eslint-disable-next-line import/named
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import api from '~/api'

export const state = () => ({
  items: {} as Record<string, any>
})

export type MediaModuleState = ReturnType<typeof state>

export const getters: GetterTree<MediaModuleState, MediaModuleState> = {
  getFiles (state) {
    return state.items
  }
}

export const mutations: MutationTree<MediaModuleState> = {
  SET_FILE (state, { id, item }) {
    // console.log('SET_FILE', id, item)
    // eslint-disable-next-line import/no-named-as-default-member
    Vue.set(state.items, id, item)
  },
  // SET_FILE_PROP (state, { id, key, value }) {
  //   console.log('SET_FILE_PROP', id, key, value, state.files)
  //   Vue.set(state.files[id], key, value)
  // },
  SET_FILES (state, value) {
    state.items = value
  },
  // REMOVE_ITEM: (state, order) => {
  //   const index = state.items.indexOf(order)
  //   if (index >= 0) {
  //     state.orders.splice(index, 1)
  //   }
  // },
  UPDATE_ITEM: (_, props) => {
    if (!props || props.length < 2) {
      throw new Error('Incoorect props')
    }
    for (const key in props[1]) {
      props[0][key] = props[1][key]
    }
  },
  CANCEL_ITEM (state, { id }) {
    const item = state.items[id]
    if (!item) {
      return
    }
    if (item.status === 'progress' && item.source && typeof item.source.cancel === 'function') {
      item.source.cancel('Operation canceled by the user.')
    } else {
      Vue.delete(state.items, id)
    }
  }
}
export const actions: ActionTree<MediaModuleState, MediaModuleState> = {
  startUploadPictures ({ commit, dispatch }, { files, objectId, parentId }) {
    commit('SET_FILES', {})
    const limit = pLimit(2)
    const items = Array.prototype.map.call(files, (file) => {
      const id = uuid()
      const src = window.URL.createObjectURL(file)

      const item = {
        id,
        file,
        parentId,
        objectId,
        name: file.name,
        size: file.size,
        preview: src,
        progress: 0,
        status: ''
      }
      commit('SET_FILE', { id, item })
      return limit(() => {
        return dispatch('startUploadFile', { id })
      })
    })
    return Promise.all(items)
  },

  startUploadFile ({ commit, state }, { id }) {
    const item = state.items[id]
    if (!item || !item.file || item.status === 'success') {
      return item
    }

    // const source = axios.CancelToken.source()
    // const config: AxiosRequestConfig = {
    //   cancelToken: source.token,
    //   progress: false,
    //   onUploadProgress: (progressEvent: { loaded: number, total: number }) => {
    //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //     commit('UPDATE_ITEM', [item, {
    //       progress: percentCompleted
    //     }])
    //   }
    // }

    commit('UPDATE_ITEM', [item, {
      status: 'progress',
      progress: 0
      // source
    }])
    const data = new FormData()
    data.append('objectId', item.objectId)
    data.append('file', item.file)
    data.append('inputs[type]', 'image') // TODO: get from item.file.type, but there is image/jpeg
    data.append('inputs[name]', item.name)
    data.append('inputs[parentId]', item.parentId || 'root')
    // config.data = data
    const promise = api.upload('extensions/media/file', data)

    return promise.then((res) => {
      commit('UPDATE_ITEM', [item, {
        status: 'success',
        progress: 100,
        preview: get(res, 'data.picture.thumbnail')
      }])
      window.URL.revokeObjectURL(item.preview)
      return res.data
    }).catch((e) => {
      // if (axios.isCancel(e)) {
      //   commit('UPDATE_ITEM', [item, {
      //     status: 'canceled'
      //   }])
      // } else {
      commit('UPDATE_ITEM', [item, {
        status: 'error',
        errorMessage: e.message
      }])
      // }
    })
  }
  // getFileData (file) {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader()
  //     reader.onloadend = () => {
  //       resolve(reader.result)
  //     }
  //     reader.readAsDataURL(file)
  //   })
  // }
}
