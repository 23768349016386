import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9667d95a = () => interopDefault(import('../pages/accounts/index.vue' /* webpackChunkName: "pages/accounts/index" */))
const _368b5adc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _154fe1e0 = () => interopDefault(import('../pages/auth/finish.vue' /* webpackChunkName: "pages/auth/finish" */))
const _830f1f20 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9a19d152 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _33eac2b0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3fdff1fe = () => interopDefault(import('../pages/auth/continue/_type.vue' /* webpackChunkName: "pages/auth/continue/_type" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _04cb9366 = () => interopDefault(import('../pages/select.vue' /* webpackChunkName: "pages/select" */))
const _e45e01ce = () => interopDefault(import('../pages/system.vue' /* webpackChunkName: "pages/system" */))
const _9378a9aa = () => interopDefault(import('../pages/system/_section/_item/_id.ts' /* webpackChunkName: "pages/system/_section/_item/_id" */))
const _07c038e2 = () => interopDefault(import('../pages/tenants.vue' /* webpackChunkName: "pages/tenants" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48dcce7b = () => interopDefault(import('../pages/accounts/_id.vue' /* webpackChunkName: "pages/accounts/_id" */))
const _c5d8aee2 = () => interopDefault(import('../pages/dashboard/_tenantId.vue' /* webpackChunkName: "pages/dashboard/_tenantId" */))
const _0f441dac = () => interopDefault(import('../pages/dashboard/_tenantId/_section/_item.ts' /* webpackChunkName: "pages/dashboard/_tenantId/_section/_item" */))
const _ffd1b9be = () => interopDefault(import('../pages/dashboard/_tenantId/_section/_item/_id.ts' /* webpackChunkName: "pages/dashboard/_tenantId/_section/_item/_id" */))
const _8a2346b0 = () => interopDefault(import('../pages/m/_tenantId.vue' /* webpackChunkName: "pages/m/_tenantId" */))
const _40601653 = () => interopDefault(import('../pages/m/_tenantId/_id.vue' /* webpackChunkName: "pages/m/_tenantId/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounts",
    component: _9667d95a,
    name: "accounts"
  }, {
    path: "/auth",
    component: _368b5adc,
    name: "auth",
    children: [{
      path: "finish",
      component: _154fe1e0,
      name: "auth-finish"
    }, {
      path: "forgot",
      component: _830f1f20,
      name: "auth-forgot"
    }, {
      path: "login",
      component: _8e1272a8,
      name: "auth-login"
    }, {
      path: "logout",
      component: _9a19d152,
      name: "auth-logout"
    }, {
      path: "register",
      component: _33eac2b0,
      name: "auth-register"
    }, {
      path: "continue/:type?",
      component: _3fdff1fe,
      name: "auth-continue-type"
    }]
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/select",
    component: _04cb9366,
    name: "select"
  }, {
    path: "/system",
    component: _e45e01ce,
    name: "system",
    children: [{
      path: ":section?/:item?/:id?",
      component: _9378a9aa,
      name: "system-section-item-id"
    }]
  }, {
    path: "/tenants",
    component: _07c038e2,
    name: "tenants"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/accounts/:id",
    component: _48dcce7b,
    name: "accounts-id"
  }, {
    path: "/dashboard/:tenantId?",
    component: _c5d8aee2,
    name: "dashboard-tenantId",
    children: [{
      path: ":section?/:item?",
      component: _0f441dac,
      name: "dashboard-tenantId-section-item",
      children: [{
        path: ":id?",
        component: _ffd1b9be,
        name: "dashboard-tenantId-section-item-id"
      }]
    }]
  }, {
    path: "/m/:tenantId?",
    component: _8a2346b0,
    name: "m-tenantId",
    children: [{
      path: ":id?",
      component: _40601653,
      name: "m-tenantId-id"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
