import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

import type { Plugin, Context } from '@nuxt/types'
const ls = new SecureLS({ isCompression: false })

const plugin: Plugin = ({ store, $vuetify }: Context) => {
  createPersistedState({
    key: 'processing-client',
    paths: ['user'],
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })(store as any)

  $vuetify.theme.dark = store.getters['user/meta'].DARK_THEME
}

export default plugin
