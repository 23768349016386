export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","name":"English","file":"en-US.js","iso":"en-US"},{"code":"ru","name":"Русский","file":"ru-RU.js","iso":"ru-RU"},{"code":"uk","name":"Українська","file":"uk-UA.js","iso":"uk-UA"},{"code":"hu","name":"Magyar","file":"hu-HU.js","iso":"hu-HU"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":true,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","name":"English","file":"en-US.js","iso":"en-US"},{"code":"ru","name":"Русский","file":"ru-RU.js","iso":"ru-RU"},{"code":"uk","name":"Українська","file":"uk-UA.js","iso":"uk-UA"},{"code":"hu","name":"Magyar","file":"hu-HU.js","iso":"hu-HU"}],
  localeCodes: ["en","ru","uk","hu"],
}

export const localeMessages = {
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'ru-RU.js': () => import('../../lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
  'uk-UA.js': () => import('../../lang/uk-UA.js' /* webpackChunkName: "lang-uk-UA.js" */),
  'hu-HU.js': () => import('../../lang/hu-HU.js' /* webpackChunkName: "lang-hu-HU.js" */),
}
