import gqlTag from 'graphql-tag'
import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { WebSocketLink } from 'apollo-link-ws'

import type { Plugin, Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import schema from '~/schema.json'
import XosenApi from '~/api'

declare module 'vue/types/vue' {
  interface Vue {
    $graphql: any
  }
}

const plugin: Plugin = (ctx: Context, inject: Inject) => {
  const token = ctx.store.state.user.token
  if (token) {
    XosenApi.setToken(token)
  }
  XosenApi.host = ctx.$config.apiUrl + 'api'
  XosenApi.language = ctx.app.i18n.locale
  XosenApi.onUnauthorize = () => {
    ctx.app.router?.push('/auth/logout')
    return true
  }

  ;(window as any).XosenApi = XosenApi

  let wsUri = ctx.app.$config.wsUrl
  if (!wsUri) {
    let url = ctx.app.$axios.defaults.baseURL
    if (!url || url === '/') {
      url = window.location.origin
    }
    url = url.replace('http:', 'ws:')
    url = url.replace('https:', 'wss:')
    if (!url.endsWith('/')) {
      url += '/'
    }
    wsUri = `${url}subscriptions`
  }
  if (!wsUri) {
    console.warn('Env url variables for subscription WS_URL was not setted')
    return
  }

  let apolloClient
  try {
  // Create the subscription websocket link
    const wsLink = new WebSocketLink({
      uri: wsUri,
      options: {
        reconnect: true,
        connectionParams () {
          return {
            authToken: ctx.store.state.user.token,
            language: ctx.app.i18n.locale
          }
        }
      }
    })

    // Create the apollo client
    apolloClient = new ApolloClient({
      link: wsLink,
      cache: new InMemoryCache(),
      connectToDevTools: true
    })

    ctx.app.wsLink = wsLink

    // Install the vue plugin
    Vue.use(VueApollo)

    const apolloProvider = new VueApollo({
      defaultClient: apolloClient
    })

    ctx.app.apolloProvider = apolloProvider
    // ctx.store.$apollo = apolloClient
    ctx.$dialog.context.apolloProvider = apolloProvider
    inject('apollo', apolloClient)
  } catch (e) {
    console.error(`Error create Apollo and WebSocket: ${e.message}`, e)
  }
  // create handler
  // const handlerFn = (name) => {
  //   return async (variables, options = {}) => {
  //     const response = await ctx.app.$axios.$post('/graphql', {
  //       ...options.params,
  //       query: schema[name],
  //       variables
  //     }, options)
  //     if (response[name]) {
  //       return response[name]
  //     }
  //     return response[name] || response[Object.keys(response)[0]]
  //   }
  // }

  const gql = {
    schema,
    subscribe (name, params, callback, onError) {
      const observer = apolloClient.subscribe({ // addSmartSubscription
        query: gqlTag(schema[name]),
        variables: params,
        result: callback
      })

      const sub = observer.subscribe({
        next (data) {
          callback(data.data[name])
        },
        error (error) {
          console.error(error)
          onError && onError(error)
        }
      })
      ;(this as any).$once('hook:beforeDestroy', () => {
        sub.unsubscribe()
      })
      return sub
    }
  }

  // for (const key in schema) {
  //   Object.defineProperty(gql, key, {
  //     get: () => handlerFn(key)
  //   })
  // }
  ctx.$dialog.context.$graphql = gql // todo: remake this to autoset
  inject('graphql', gql)
}
export default plugin
