<template>
  <div class="error-page">
    <v-container
      fill-height
      style="height: calc(100vh - 160px);"
    >
      <v-layout align-center>
        <v-flex text-center>
          <h1 class="display-2 primary--text">
            {{ $t('E_WHOOPS') }}, {{ error.statusCode }}
          </h1>
          <p class="pa-4 display-1">
            {{ $t(error.message) }}
          </p>
          <v-btn
            color="warning"
            @click="$router.go(-1)"
          >
            {{ $t('Go back') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>

export default {
  layout: () => {
    return 'default'
  },
  props: {
    error: [String, Error, Object]
  }
}
</script>
