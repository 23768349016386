import Vue from 'vue'

import type { Plugin, Context } from '@nuxt/types'

import { CrudGrid } from 'vuetify-crud'

import VueFlag from 'vue-flag/dist/vue-flag.esm'

import { VActiveIcon, VImage } from 'vuetify-addons'
import 'vuetify-addons/dist/vuetify-addons.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyConfirm from 'vuetify-confirm'
import VueCurrencyInput from 'vue-currency-input'
import { Inject } from '@nuxt/types/app'
import { setup, showInfo } from '~/components/common/utils/dialog'
import VStatus from '~/components/common/addons/VStatus.vue'
import VMoneyText from '~/components/common/addons/VMoneyText.vue'
import VUser from '~/components/common/addons/VUser.vue'
import VUserAvatar from '~/components/common/addons/VUserAvatar.vue'

import 'vue-flag/dist/vue-flag.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.use(VueCurrencyInput)

Vue.component('VActiveIcon', VActiveIcon)
Vue.component('VImage', VImage)
Vue.component('CrudGrid', CrudGrid)
Vue.component('VStatus', VStatus)
Vue.component('VMoneyText', VMoneyText)
Vue.component('VUser', VUser)
Vue.component('VUserAvatar', VUserAvatar)
Vue.component('VueFlag', VueFlag)

Vue.filter('rating', function (value) {
  if (typeof value === 'number') {
    return value.toFixed(1)
  }
  return value
})

declare module 'vue/types/vue' {
  interface Vue {
    $can: (permission: string, rpleType?: any) => boolean
  }
}

const plugin: Plugin = (ctx: Context, inject: Inject) => {
  if (process.client) {
    window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault()
      // Stash the event so it can be triggered later.
      // @ts-ignore
      ctx.app.store.commit('SET_INSTALL_PROMPT', event)
    })
  }

  window.addEventListener('offline', () => {
    showInfo('You are offline', 'error')
  })

  window.addEventListener('online', () => {
    showInfo('You are online', 'success')
  })

  setup({
    dialog: Vue.prototype.$dialog,
    i18n: ctx.app.i18n
  })

  Vue.use(VuetifyConfirm, {
    vuetify: ctx.app.vuetify
  })

  Vue.use(VueGoogleMaps, {
    load: {
      get language () {
        return ctx.app.i18n.locale
      },
      key: ctx.app.$config.googleMapsKey,
      libraries: 'places',
      v: 3.42
    }
  })

  // @ts-ignore
  ctx.$dialog.context.$can = ctx.store.getters['user/can']
  // ctx.$can = can
  inject('can', ctx.store.getters['user/can'])
}

export default plugin
