import { Middleware, Context } from '@nuxt/types'

const authMiddleware: Middleware = ({ store, redirect, route }: Context) => {
  // If user not connected, redirect to /auth/login
  if (!store.getters.isAuthenticated) {
    return redirect('/auth/login?redirect_url=' + route.path)
  }
}

export default authMiddleware
