
// eslint-disable-next-line import/named
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import api, { User, Region } from '~/api'

export const state = () => ({
  search: '',
  navigationDrawer: null,
  installPrompt: null,
  ipInfo: null
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  SET_INSTALL_PROMPT (state, prompt) {
    state.installPrompt = prompt
  },
  SET_NAVIGATION_DRAWER (state, value) {
    state.navigationDrawer = value
  }
}

export const getters: GetterTree<RootState, RootState> = {
  inputLanguages (): string[] {
    return ['en', 'ru', 'uk', 'de', 'hu'] // [ 'en', 'ru', 'uk', 'sk', 'ro', 'cs', 'fr', 'de', 'hu', 'it', 'es', 'tr', 'pl' ]
  },
  profile (state: any): User {
    return state.user.profile
  },
  isAuthenticated (state: any): boolean {
    return !!state.user.token && !!state.user.profile
  },
  isRemembered (state: any): boolean {
    return state.user.rememberMe
  },
  installPrompt (state) {
    return state.installPrompt
  },
  canInstall (state): boolean {
    return Boolean(state.installPrompt) && 'BeforeInstallPromptEvent' in window // todo
  },
  navigationDrawer (state) {
    return state.navigationDrawer
  }
}
const issueLabels = {}
const organizationCustomers = {}

export const actions: ActionTree<RootState, RootState> = {
  // nuxtServerInit ({ commit }, { req }) {
  // },

  uploadPicture (_, { file, model, params = {} }) {
    const formData = new FormData()
    formData.append('file', file)
    for (const key in params) {
      formData.append(key, params[key])
    }
    return api.upload(`upload/${model}/picture`, formData)
  },

  async getIpInfo ({ commit, state }) {
    if (!state.ipInfo) {
      const ipInfo = await api.get('ipinfo')
      commit('SET_IPINFO', ipInfo)
    }
    return state.ipInfo
  },

  retrieveOrganizationCustomer (_, id) {
    if (!organizationCustomers[id]) {
      organizationCustomers[id] = api.get(`~/@organization/customers/${id}`, { fields: 'address' }).catch(() => {})
    }
    return organizationCustomers[id]
  },

  retrieveIssueLabels (_, clear: boolean) {
    if (clear) {
      api.tenantId && (issueLabels[api.tenantId!] = null)
    }
    if (!issueLabels[api.tenantId!]) {
      issueLabels[api.tenantId!] = api.get('~/@issue/labels').catch(() => {})
    }
    return issueLabels[api.tenantId!]
  },

  async retrieveRegion (_, id: string) {
    if (!id) {
      return
    }
    if (!regionsCache[id]) {
      await retrieveRegion(id)
    }
    return regionsCache[id]
  }
}

// export const plugins = [
//   // createMutationsSharer({ predicate: [
//   //   'LOGIN',
//   //   'LOGOUT',
//   //   'user/SET_TOKENS',
//   // ]})
// ]

const regionsCache = {}
let bulk: any[] = []
let resolvers: any[] = []
let activeTimeout: any = null

function retrieveRegions () {
  const ids = bulk
  const resvrs = resolvers
  bulk = []
  resolvers = []

  return api.post<Region[]>('dictionaries/regions/search', { ids })
    .then((res) => {
      res.forEach((i) => {
        regionsCache[i.id] = i
      })
      resvrs.forEach((rsl: any) => rsl())
      activeTimeout = null
    })
    .catch(() => {
      resvrs.forEach((rsl: any) => rsl())
      activeTimeout = null
    })
}

const retrieveRegion = (id) => {
  return new Promise((resolve: any) => {
    bulk.push(id)
    resolvers.push(resolve)
    if (activeTimeout) {
      return
    }
    activeTimeout = setTimeout(retrieveRegions, 10)
  })
}
