<template>
  <v-app>
    <v-container fluid fill-height class="background-container">
      <transition name="slide-x-transition">
        <nuxt />
      </transition>
    </v-container>
  </v-app>
</template>

<script>
export default {
  layout: 'default'
}
</script>

<style lang="scss" scoped>
  .background-container {
    padding: 0 8px;
    // TODO: vuetify2 temp
    // background: $random-background-image top center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    // TODO: vuetify2 temp
    // background-color: $background-color;
  }
</style>
