<template>
  <div
    v-if="item"
    class="VUserAvatar d-flex align-center"
    :class="{
      _small: small,
      '_x-small': xSmall,
      _large: large
    }"
    :title="item.name"
    :style="`color: var(--v-${getColor}-base)`"
  >
    <v-badge
      v-if="!onlyName"
      bordered
      :value="showOnline && Boolean(item.isOnline)"
      bottom
      color="success"
      dot
      offset-x="10"
      offset-y="10"
    >
      <v-avatar :size="xSmall ? 16 : small ? 24 : large ? 42 : 36" :color="getColor">
        <v-img
          v-if="item.thumbnailUrl || item.imageUrl"
          :src="item.thumbnailUrl || item.imageUrl"
          alt="Avatar"
        />
        <span
          v-else
          class="initials"
        >
          {{ getText }}
        </span>
      </v-avatar>
    </v-badge>
    <span v-if="(withName || onlyName) && (item.name || item.email)" :class="onlyName ? 'pl-0' : small || xSmall ? 'pl-1' : 'pl-2'">
      <div>
        {{ item.name || item.email }}
      </div>
      <slot name="subtitle">
        <div v-if="subtitle" class="caption">
          {{ subtitle }}
        </div>
      </slot>
    </span>
  </div>
</template>

<script>

// const backgroundColors = [
//   '#F44336', '#FF4081', '#9C27B0', '#673AB7',
//   '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688',
//   '#4CAF50', '#8BC34A', '#CDDC39', /* '#FFEB3B' , */ '#FFC107',
//   '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B']

export default {
  props: {
    item: Object,
    small: Boolean,
    xSmall: Boolean,
    large: Boolean,
    withName: Boolean,
    onlyName: Boolean,
    subtitle: String,
    showOnline: Boolean
  },
  computed: {
    getText () {
      return this.getInitials(this.item.name || this.item.email || '')
      // .charAt(0).toUpperCase()
      // .match(/\b\w/g) || []).slice(0, 2).map(i => i.toUpperCase()).join(' ')
    },
    getColor () {
      return this.stringToHslColor(this.item.name || this.item.email || 'Unknown', 60, 45)
    }
  },
  methods: {
    stringToHslColor (str, saturation, lightness) {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      const h = hash % 360
      return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)'
    },
    getInitials (username) {
      const parts = username.split(/[ -]/)
      let initials = ''
      for (let i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0)
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
      }
      initials = initials.substr(0, 3).toUpperCase()
      return initials
    }

  }
}
</script>

<style lang="scss">
  .VUserAvatar {
    font-size: 14px;
    white-space: nowrap;
    .initials {
      color: white;
    }
    &._small {
      font-size: 0.75rem;
    }
    &._x-small {
      font-size: 0.65rem;
    }
    &._large {
      font-size: 16px;
    }
  }
</style>
