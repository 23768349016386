import Vue from 'vue'
let $dialog = Vue.prototype.$dialog
let vm
export function showError (_error) {
  let message = _error.message
  if (_error.code) {
    message = vm.$t(_error.code)
  }
  $dialog.notify.error(message)
}

export function setup ({ dialog, i18n }) {
  $dialog = dialog
  vm = new Vue({
    i18n
  })
}

type DialogConfirmOptions = {
  name?: string
  message?: string
  title?: string
  falseText?: string
  trueText?: string
}

export function showDeleteConfirm (handler: Function, options: DialogConfirmOptions = {}) {
  let message = options.message
  if (!message) {
    if (options.name) {
      message = vm.$t('Are you sure you want to delete <b>{0}</b>?', [options.name])
    } else {
      message = vm.$t('Are you sure you want to delete item?')
    }
  }
  return $dialog.warning({
    text: message,
    title: options.title || vm.$t('Warning'),
    actions: {
      false: options.falseText || vm.$t('Cancel'),
      true: {
        text: options.trueText || vm.$t('Delete'),
        color: 'red',
        handler: () => handler().catch(showError)
      }
    }
  })
}

export function showInfo (message, type = 'info') {
  return $dialog.notify[type](message)
}

export function showConfirm (message, handler, options: DialogConfirmOptions = {}) {
  return $dialog.warning({
    text: message,
    title: options.title || vm.$t('Confirmation'),
    actions: {
      false: options.falseText || vm.$t('No'),
      true: {
        text: options.trueText || vm.$t('Yes'),
        handler: () => (handler() || Promise.resolve()).catch(showError)
      }
    }
  })
}

export function showWarning (message, handler, options: DialogConfirmOptions = {}) {
  return $dialog.warning({
    text: message,
    title: options.title || vm.$t('Warning'),
    actions: {
      false: options.falseText || vm.$t('Cancel'),
      true: {
        text: options.trueText || vm.$t('Ok'),
        handler: () => handler && handler().catch(showError)
      }
    }
  })
}
