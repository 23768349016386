<template>
  <v-menu
    offset-y
    left
    :close-on-content-click="false"
    :min-width="344"
  >
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <VUserAvatar :item="profile" />
      </v-btn>
    </template>
    <v-sheet
      class="mx-auto"
      :min-width="344"
    >
      <div>
        <VUserAvatar
          large
          class="subtitle-1 pa-4"
          :item="profile"
          with-name
          :subtitle="profile.email"
        />
        <v-divider />
        <v-list>
          <template v-for="item in menu">
            <v-list-item
              v-if="item.visible"
              :key="item.title"
              @click="onClick(item)"
            >
              <v-icon left>
                {{ item.action }}
              </v-icon>
              {{ item.text }}
            </v-list-item>
          </template>

          <v-list-item>
            <v-icon left>
              brightness_medium
            </v-icon>
            {{ $t('Dark theme') }}
            <v-spacer />
            <v-switch
              v-model="$vuetify.theme.isDark"
              hide-details=""
              color="warning"
              class="py-0 my-0"
              @change="changeIsDark"
            />
          </v-list-item>
        </v-list>
        <v-divider />
      </div>

      <div class="pa-4 d-flex align-center">
        <VLocaleSwitcher reload />
        <v-spacer />
        <v-btn
          color="warning"
          outlined
          rounded
          @click="logoutHandle"
        >
          {{ $t('Log out') }}<v-icon>exit_to_app</v-icon>
        </v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { VLocaleSwitcher } from 'vuetify-addons'

export default Vue.extend({
  components: {
    VLocaleSwitcher
  },
  computed: {
    ...mapGetters([
      'profile',
      'installPrompt',
      'canInstall',
      'navigationDrawer',
      'isRemembered'
    ]),
    menu () {
      return [
        {
          icon: 'trending_up',
          text: this.$t('Account settings'),
          action: 'account_box',
          visible: true,
          route: '/profile'
        },
        {
          icon: 'settings',
          text: this.$t('System settings'),
          action: 'settings',
          route: '/system',
          visible: !!this.profile.systemRole
        },
        {
          icon: 'get_app',
          text: this.$t('Install application'),
          action: 'settings',
          visible: this.canInstall,
          handler: this.installPromptClick.bind(this)
        }
      ]
    }
  },
  methods: {
    onClick (item) {
      if (item.handler) {
        item.handler()
      } else {
        this.$router.push(item.route)
      }
    },

    changeIsDark () {
      this.$store.dispatch('user/setMeta', {
        key: 'DARK_THEME',
        value: this.$vuetify.theme.dark
      })
    },

    async installPromptClick () {
      this.installPrompt.prompt()
      const res = await this.installPrompt.userChoice
      if (res && res.outcome === 'accepted') {
        this.$store.commit('SET_INSTALL_PROMPT', null)
      }
    },

    async logoutHandle () {
      await this.$dialog.warning({
        text: this.$t('Do you really want to exit?'),
        title: this.$t('Warning'),
        persistent: true,
        actions: {
          no: this.$t('No'),
          yes: {
            color: 'warning',
            text: this.$t('Yes'),
            handler: () => {
              this.$emit('onLogOut')
            }
          }
        }
      })
    }
  }
})
</script>
