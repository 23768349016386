<template>
  <span v-if="user" class="VUser">
    <VUserAvatar v-bind="$props" :item="user" />
  </span>
  <span v-else-if="id" class="caption">
    {{ $t('Deleted user') }}
  </span>
</template>

<script>

export default {
  props: {
    id: String,
    small: Boolean,
    xSmall: Boolean,
    large: Boolean,
    withName: Boolean,
    showOnline: Boolean,
    onlyName: Boolean,
    subtitle: String
  },
  data () {
    return {
      user: null
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (val) {
        if (val) {
          this.getUser()
        } else {
          this.user = null
        }
      }
    }
  },
  methods: {
    async getUser () {
      this.user = await this.$store.dispatch('user/retrieveUser', this.id)
    }
  }
}
</script>

<style scoped>
  .VUser{
    display: inline-block;
  }
</style>
