import { ru, uk, hu } from 'vuetify/src/locale'

import type { Plugin, Context } from '@nuxt/types'

const plugin: Plugin = ({ app, store }: Context) => {
  const locales = app.vuetify!.framework.lang.locales
  locales.ru = ru
  locales.uk = uk
  locales.hu = hu
  app.vuetify!.framework.lang.current = app.i18n.locale
  // beforeLanguageSwitch called right before setting a new locale
  app.i18n.beforeLanguageSwitch = (_oldLocale: string, newLocale: string) => {
    store.commit('tag/SET_NEED_RELOAD', true)
    app.vuetify!.framework.lang.current = newLocale
  }
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (_oldLocale: string, _newLocale: string) => {}
}
export default plugin
