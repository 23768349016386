import { Middleware, Context } from '@nuxt/types'

declare module '@nuxt/types' {
  interface Context {
      $isMobile: boolean;
  }
}

const isMobileMiddleware: Middleware = (ctx: Context) => {
  const userAgent = ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent
  ctx.$isMobile = /mobile/i.test(userAgent || '')
}

export default isMobileMiddleware
