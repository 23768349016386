<template>
  <v-app>
    <v-main>
      <Toolbar :has-menu="hasMenu" />
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>

import Toolbar from '~/components/site/Toolbar'
export default {
  components: {
    Toolbar
  },
  computed: {
    hasMenu () {
      const path = this.$route.path || ''
      return path.startsWith('/dashboard') || path.startsWith('/system')
    }
  }
}
</script>
