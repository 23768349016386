const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['is-mobile'] = require('../middleware/is-mobile.ts')
middleware['is-mobile'] = middleware['is-mobile'].default || middleware['is-mobile']

middleware['oauth'] = require('../middleware/oauth.ts')
middleware['oauth'] = middleware['oauth'].default || middleware['oauth']

export default middleware
