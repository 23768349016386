import { RequestCredentials } from 'node-fetch'
import IRESTClient from './IRESTClient'
import IRESTClientBuilder from './IRESTClientBuilder'
import DefaultRESTClientBuilder from './DefaultRESTClientBuilder'
export type UUID = string

export interface Error {
  code: string
  message: string
  status: string
  data: any
  errors: Array<any>
}

export interface User {
  id: UUID,
  name: string
  email: string
  active: boolean
  firstName: string
  lastName: string
  data?: Record<string, any>
  imageUrl?: string
  mobilePhone?: string
  timezone?: string
}

export interface Currency {
  id: UUID
  name: string
  names: Record<string, any>
  code: string
  digits: number
  symbol: string
  active: boolean
}

export interface Country {
  id: UUID
  name: string
  officialName: string
  cca3: string
  code: string
  config: Record<string, any>
  currencyId: UUID
  currency: Currency
  latitude: number
  longitude: number
  prefix: string
  active: boolean
}

export interface PaginationResponse<T> {
  data: Array<T>
  meta: {
    total: number
  }
}

export interface LoginRequest {
  email: string,
  password: string
  rememberMe?: boolean
}

export interface LoginResponse {
  token: string
  type: string
  twoFactorId: string
  changePasswordId: string
}

export interface RegisterRequest {
  email: string
  firstName: string
  lastName: string
  password: string
  recaptchaToken: string
}

export interface RegisterResponse {
  user: User
}

export interface SocialLoginRequest {
  id: string
  token: string
  rememberMe: boolean
}

export interface SocialProvider {
  clientId: string
  id: string
  text: string
  type: string
}

export interface Extension {
  readonly id: UUID
  name: string
  descriptions: string
  imageUrl: string
  active: boolean
  installations: number
  rate: number
  slug: string
  votes: number
  installed: boolean
}

export interface Tenant {
  readonly id: UUID
  name: string
  slug: string
  imageUrl: string
  currencyId: string
  active: boolean
  verified: boolean
  extensions?: Extension[]
}

export interface Microservice {
  id: UUID
  name: string
  host: string
  extension: Extension
  extensionId: UUID
  token: string
  config: Record<string, any>
  isDefault: boolean
  active: boolean
}

export interface ChangePasswordRequest {
  changePasswordId?: string
  currentPassword?: string
  password: string
  confirmPassword: string
  rememberMe?: boolean
}

export interface TenantRole {
  id: UUID,
  name: string
  type: string
  permissions: Record<string, any>
}

export interface TenantMember {
  accepted: boolean
  acceptedAt: Date
  expiresAt: Date
  priority: boolean
  roleId: UUID
  tenantId: UUID
  userId: UUID
  user: User
  tenant: Tenant
  role: TenantRole
}

/**
 * Organization types
 */

export interface OrgTariffGroup {
  id: UUID
  name: string
  changeable: boolean
  pausable: boolean
  suspendable: boolean
  itemsSchema: string
  itemsMaxQty: number
  config: Record<string, any>
}
export interface OrgTariff {
  id: UUID
  name: string
  description: string,
  tariffGroupId: UUID
  active: boolean
  price: number
  prices: any
  periodType: string
  periodQty: number
  paymentType: string
  tariffGroup: OrgTariffGroup
}

export interface OrgFirm {
  id: UUID
  name: string
  director: string
  active: boolean
}

export interface OrgObjectUserLink {
  userId: UUID
  role: string
}

export type OrgObjectTypes = 'bank' | 'cash' | 'outlet' | 'storage' | 'firm'
export interface OrgObject {
  id: UUID | null
  name: string
  type: OrgObjectTypes
  users: OrgObjectUserLink[]
  firmId: UUID
  firm: OrgFirm
  active: boolean,
  acquiringEnabled: boolean,
  acquiringConfig: Record<string, any>
}

export interface OrgUnit {
  id: UUID
  name: string
  active: boolean
  type: null | 'F' | 'H' | 'D' // NULL - Integer, (F)ractional, (H)alf, (Q)uarter
}

export interface Region {
  id: UUID
  name: string
  district: string
  state: string
}

export interface OrgAddress {
  regionId: UUID
  addressLine1: string
  addressLine2: string
  description: string
  postalCode: string
}

export interface OrgPortfolioLock {
  amount: number
  description: string
}

export interface OrgPortfolio {
  id: UUID
  balance: number
  limit: number
  overdraft: number
  overdraftExpiresAt: Date
  currencyId: number
  locks: OrgPortfolioLock[]
}

export interface OrgSubscription {
  id: UUID
  createdAt: Date
  customerId: string
  description: string
  finishAt: Date
  paymentAt: Date
  price: number
  quantity: number
  startAt: Date
  status: string
  tariff: OrgTariff
  tariffId: UUID
  updatedAt: Date
}
export interface OrgCustomer {
  id: UUID
  name: string
  code: string
  fullName: string
  email: string
  phone: string
  phone2: string
  serialnum: number
  idn: string
  passport: string
  description: string
  portfolio: OrgPortfolio
  address: OrgAddress
  subscriptions: OrgSubscription[]
  active: boolean
}

export interface OrgDocument {
  id: UUID
}

export interface OrgProduct {
  id: UUID
  name: string
  type: string
  active: boolean
  config: Record<string, any>
}

export interface OrgService {
  id: UUID
  name: string
  active: boolean
  config: Record<string, any>
  labels: Array<any>
  protocol: string
  statisticEnabled: boolean
  statisticProcessedAt: Date
  subscriptionsCount: number
  tariffGroups: OrgTariffGroup[]
  message: string
  state: string
}
/**
 * Issue types
 */
export interface IssueLabel {
  id: UUID
  name: string
  color: string
  description: string
  priority: boolean
}

export interface IssueItem {
  id: UUID
  title: string,
  titleText: string,
  description: string
  descriptionText: string
  active: boolean
  hists: any[]
  links: any[]
}
export interface IssueComment {
  id: UUID
  content: string
  contentText: string
}

class XosenApiClient {
  public clientBuilder: IRESTClientBuilder = new DefaultRESTClientBuilder()
  public credentials?: RequestCredentials
  public onUnauthorize?: Function

  constructor (
    public host: string,
    public apiToken?: string,
    public tenantId?: UUID,
    public language?: string
  ) {
    this.host = host
  }

  /**
   * Sets the tenant id, that will be included in the X-FusionAuth-TenantId header.
   *
   * @param {string | null} tenantId The value of the X-FusionAuth-TenantId header.
   * @returns {FusionAuthClient}
   */
  public setTenantId (tenantId: UUID | undefined): XosenApiClient {
    this.tenantId = tenantId
    return this
  }

  public setToken (token?: string) {
    this.apiToken = token ? 'Bearer ' + token : undefined
  }

  /**
   * Login function
   */
  public login (request: LoginRequest): Promise<LoginResponse> {
    return this.post<LoginResponse>('auth/login', request)
  }

  public logout () {
    return this.get('auth/logout').catch(() => {})
  }

  public register (request: RegisterRequest): Promise<RegisterResponse> {
    return this.post<RegisterResponse>('auth/register', request)
  }

  public retrieveSocialProviders (): Promise<SocialProvider[]> {
    return this.get<SocialProvider[]>('auth/social-providers')
  }

  public socialLogin (request: SocialLoginRequest): Promise<LoginResponse> {
    return this.post<LoginResponse>('auth/login/social', request)
  }

  public loginTwoFactor (request: any): Promise<LoginResponse> {
    return this.post<LoginResponse>('auth/continue/two-factor', request)
  }

  public loginContinueStep (step: string, data: Record<string, any>) {
    return this.post<LoginResponse>(`auth/continue/${step}`, data)
  }

  public retrieveTwoFactor (): Promise<any> {
    return this.get<any>('profile/two-factor')
  }

  public saveTwoFactor (request: any): Promise<any> {
    return this.post<any>('profile/two-factor', request)
  }

  public deleteTwoFactor (code: string): Promise<any> {
    return this.delete<any>(`profile/two-factor/${code}`)
  }

  public loginChangePassword (request: ChangePasswordRequest): Promise<any> {
    return this.post<LoginResponse>('auth/continue/change-password', request)
  }

  public changePassword (request: ChangePasswordRequest): Promise<any> {
    return this.post<LoginResponse>('profile/password', request)
  }

  public forgotPassword (uid): Promise<any> {
    return this.post('auth/forgot', { uid })
  }

  public accountExists (uid): Promise<boolean> {
    return this.post<any>('auth/account-exists', { email: uid })
      .then(res => res.result)
  }

  public retrieveProfile (): Promise<User> {
    return this.get<User>('profile')
  }

  public updateProfile (request: Partial<User>): Promise<User> {
    return this.post<User>('profile', request)
  }

  public retrieveTenant (tenantId: UUID): Promise<TenantMember> {
    return this.get<TenantMember>(`tenant/${tenantId}`)
  }

  public retrieveTenants (): Promise<TenantMember[]> {
    return this.get<TenantMember[]>('tenant')
  }

  public saveTenant (tenantId: UUID, request: Partial<Tenant>): Promise<Tenant> {
    return this.save<Tenant>('tenant', tenantId, request)
  }

  public deleteTenant (tenantId: UUID): Promise<Tenant> {
    return this.delete<Tenant>(`tenant/${tenantId}`)
  }

  public acceptTenantMembership (tenantId): Promise<TenantMember> {
    return this.post<TenantMember>(`tenant/${tenantId}/membership`)
  }

  public leaveTenantMembership (tenantId: UUID): Promise<TenantMember> {
    return this.delete<TenantMember>(`tenant/${tenantId}/membership`)
  }

  public retrieveTenantMembers (request: any): Promise<PaginationResponse<TenantMember>> {
    return this.get<PaginationResponse<TenantMember>>('~/members', request)
  }

  public retrieveTenantRoles (): Promise<TenantRole[]> {
    return this.get<TenantRole[]>('~/roles')
  }

  public retrieveTenantUsers (): Promise<User[]> {
    return this.get<User[]>('~/members/list')
  }

  public retrieveTenantExtensions (): Promise<Extension[]> {
    return this.get<Extension[]>('~/extensions')
  }

  public retrieveTenantExtension (extensionId: UUID): Promise<Extension> {
    return this.get<Extension>(`~/extensions/${extensionId}`)
  }

  public installTenantExtension (extensionId: UUID): Promise<Extension> {
    return this.put<Extension>('~/extensions/', extensionId)
  }

  public uninstallTenantExtension (extensionId: UUID): Promise<Extension> {
    return this.delete<Extension>('~/extensions/', extensionId)
  }

  public searchTenantMembersForInvite (query: string): Promise<TenantMember[]> {
    return this.get<TenantMember[]>('~/members/search', { query })
  }

  public retrieveTenantMember (userId: UUID): Promise<TenantMember> {
    return this.get<TenantMember>(`~/members/${userId}`)
  }

  public saveTenantMember (userId: UUID, request: any): Promise<Tenant> {
    return this.save<Tenant>('~/members', userId, request)
  }

  public resendTenantMemberInvitation (userId: UUID): Promise<Tenant> {
    return this.post(`~/members/${userId}/resend`)
  }

  public deleteTenantMember (userId: UUID): Promise<TenantMember> {
    return this.delete<TenantMember>(`~/members/${userId}`)
  }

  public retrieveCurrencies (): Promise<Currency[]> {
    return this.get<Currency[]>('dictionaries/currencies')
  }

  public prepareUrl (url: string) {
    if (url.startsWith('~')) {
      url = url.replace('~', `tenant/${this.tenantId}`)
    }
    if (url.includes('/@')) {
      url = url.replace('/@', '/extensions/')
    }
    return url
  }

  public upload (uri, data: FormData, method: string = 'POST') {
    const query = this.start<any, Error>()
      .withUri(this.prepareUrl(uri))
      .withMethod(method)
    // @ts-ignore
    query.body = data
    return query.run()
  }

  public download (uri, data?: any, method: string = 'GET') {
    const query = this.start<Blob, Error>()
      .withUri(this.prepareUrl(uri))
      .withParameters(data)
      .withHeader('download', 'true')
      .withMethod(method)
    return query.run()
  }

  public get<RT, ERT = Error> (uri: string, data?: any): Promise<RT> {
    return this.start<RT, ERT>()
      .withUri(this.prepareUrl(uri))
      .withParameters(data)
      .withMethod('GET')
      .run()
  }

  public post<RT, ERT = Error> (uri: string, data?: any): Promise<RT> {
    return this.start<RT, ERT>()
      .withUri(this.prepareUrl(uri))
      .withJSONBody(data)
      .withMethod('POST')
      .run()
  }

  public put<RT, ERT = Error> (uri: string, id?: UUID | number, data?: any): Promise<RT> {
    return this.start<RT, ERT>()
      .withUri(this.prepareUrl(uri))
      .withUriSegment(id)
      .withJSONBody(data)
      .withMethod('PUT')
      .run()
  }

  public delete<RT = any, ERT = Error> (uri: string, id?: UUID, data?: any): Promise<RT> {
    return this.start<RT, ERT>()
      .withUri(this.prepareUrl(uri))
      .withUriSegment(id!)
      .withJSONBody(data)
      .withMethod('DELETE')
      .run()
  }

  public save<RT, ERT = Error> (uri: string, id: UUID | number | null | undefined, data?: any): Promise<RT> {
    return id
      ? this.put<RT, ERT>(uri, id, data)
      : this.post<RT, ERT>(uri, data)
  }

  private start<RT, ERT> (): IRESTClient<RT, ERT> {
    return this.startAnonymous<RT, ERT>()
      .withAuthorization(this.apiToken)
      .withCredentials('include')
  }

  private errorHandler (error) {
    if (!error) {
      return
    }
    if (error.code === 'E_UNAUTHORIZED_ACCESS' && this.onUnauthorize) {
      return this.onUnauthorize()
    }
  }

  private startAnonymous<RT, ERT> (): IRESTClient<RT, ERT> {
    const client = this.clientBuilder.build<RT, ERT>(this.host)

    client.withHeader('Accept', 'application/json')
    if (this.tenantId !== null) {
      client.withHeader('X-Xosen-TenantId', this.tenantId!)
    }
    if (this.language) {
      client.withHeader('language', this.language)
    }

    if (this.credentials != null) {
      client.withCredentials(this.credentials)
    }
    client.onError = this.errorHandler.bind(this)

    return client
  }
}

export default XosenApiClient
