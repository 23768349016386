<template>
  <v-overlay :value="true" :opacity="1">
    <div class="PictureCropper d-flex justify-center align-center">
      <img v-if="!editMode" class="picture" :src="imgSrc">
      <vue-cropper
        v-if="editMode"
        ref="cropper"
        class="PictureCropper-picture"
        :guides="true"
        :view-mode="0"
        drag-mode="crop"
        :auto-crop-area="autoCropArea"
        :background="false"
        :rotatable="true"
        :src="imgSrc"
        :aspect-ratio="aspectRatio"
        alt="Source Image"
      />
      <v-sheet dark class="PictureCropper-buttons d-flex justify-center">
        <v-icon @click="$emit('submit')">
          close
        </v-icon>
        <v-icon v-if="!editMode" @click="editMode = true">
          edit
        </v-icon>
        <v-icon v-if="editMode" @click="rotate">
          refresh
        </v-icon>
        <v-icon @click="cropImage">
          check
        </v-icon>
      </v-sheet>
    </div>
  </v-overlay>
</template>

<script>
import Vue from 'vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default Vue.extend({
  components: {
    VueCropper
  },
  props: {
    srcData: String,
    aspectRatio: Number,
    autoCropArea: {
      type: Number,
      default: 1
    },
    openInEditMode: Boolean
  },
  data () {
    return {
      imgSrc: this.srcData,
      editMode: this.openInEditMode
    }
  },
  methods: {
    cropImage () {
      const src = this.editMode ? this.$refs.cropper.getCroppedCanvas().toDataURL() : this.imgSrc
      this.$emit('submit', src)
    },
    rotate () {
      this.$refs.cropper.rotate(90)
    }
  }
})
</script>

<style lang="scss">
  .PictureCropper {
    flex-flow: column;
    width: 90vw;
    height: 90vh;
    &-picture {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
    &-buttons .v-icon {
      font-size: 48px;
      margin-right: 8px;
    }
  }
</style>
